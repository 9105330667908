import { Colors } from "@constants";
import {
  ArticleFamily,
  BookingOrder,
  CancelBookingOrderCommand,
  CancelOrderCommand,
  CartSummaryAdmissionAndPrice,
  ConfirmOrderCommand,
  CreateBookingOrderCommand,
  AddOrderRatingCommand,
  AddOrderRatingResponse,
  IAmount,
  IPaymentTypes,
  ManagementMode,
  OfferSlot,
  OfferTemplateWithdrawalType,
  Order,
  OrderItem,
  OrderState,
  OrderItemFormula,
  PaymentMethod,
  UpdateBookingOrderSlotCommand,
  UpsertOrderCommand,
  RecentOrdersToRateResponse, DiscardOrderRatingNotificationCommand,
} from '@foodi/core';
import { OrderThunks, OffersThunks, OffersActions } from "@modules";
import { getDecodedId } from "@utils";
import _ from "lodash";
import moment from "moment";
import { Dispatch } from "react";
import { I18n } from "react-redux-i18n";
import { mapValues, isObject } from "lodash";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";
export class OrderViewModel {
  constructor(private dispatch: Dispatch<any>, private idGuest: string) {}
  private static readonly TODAY_DATE = new Date();

  async getBookingOrdersResult() {
    return this.dispatch(
      OrderThunks.getBookingOrders({
        idGuest: "",
        fromDate: moment().startOf("day").toISOString(),
      })
    );
  }

  async getOrdersResult() {
    return this.dispatch(
      OrderThunks.getOrders({
        idGuest: this.idGuest,
        fromDate: moment().startOf("day").toISOString(),
        offerTemplateTypes: "CLICK_COLLECT,TABLE_SERVICE",
        withdrawalTypes:
          "POS_AT_SITE,TABLE_SERVICE,POS_TAKE_AWAY,CONNECTED_LOCKERS,POS_CLICK_SERVE,INSTANT_CLICK_COLLECT,CLICK_AND_PICK_UP",
      })
    );
  }

  async getAllOrdersResult() {
    if (!this.idGuest) return;
    return this.dispatch(
      OrderThunks.getAllOrders({
        idGuest: this.idGuest,
        fromDate: moment()
        .subtract(1, 'year')
        .startOf('day')
        .toISOString(),
        offerTemplateTypes: "CLICK_COLLECT,TABLE_SERVICE",
        withdrawalTypes:
          "POS_AT_SITE,TABLE_SERVICE,POS_TAKE_AWAY,CONNECTED_LOCKERS,POS_CLICK_SERVE,INSTANT_CLICK_COLLECT,CLICK_AND_PICK_UP",
      })
    );
  }

  async upsertOrder({
    idGuest,
    idOffer,
    items,
    formulaItems,
    withdrawRange,
    tableNumber,
    comment,
    idPickupPoint
  }: UpsertOrderCommand) {
    try {
      const result = await this.dispatch(
        OrderThunks.upsertOrder({
          idGuest,
          idOffer,
          items,
          formulaItems,
          withdrawRange,
          tableNumber,
          comment,
          idPickupPoint
        })
      );

      return {
        //@ts-ignore
        ...result,
      };
    } catch (e) {
      return {
        //@ts-ignore
        upsertOrderError: this.outOfStockErrorFormatter(e?.cause),
      };
    }
  }

  outOfStockErrorFormatter(error: any) {
    if (error) {
      const errorItems = error?.error?.graphQLErrors?.find(
        (e: any) => e?.message === "OUT_OF_STOCK"
      )?.extensions?.exception?.items;
      const errorItemsFormula = error?.error?.graphQLErrors?.find(
        (e: any) => e?.message === "OUT_OF_STOCK"
      )?.extensions?.exception?.formulaItems;
      const [formulaItems, formulaSubItems] = errorItemsFormula.reduce(
        ([formulaItems, formulaSubItems]: any[], item: any) => {
          return !item?.idOfferItemFormulaParent
            ? [[...formulaItems, { id: item.idOfferItemFormula }], formulaSubItems]
            : [formulaItems, [...formulaSubItems, item]];
        },
        [[], []]
      );
      const errorItemsWithFormulaProducts = [...errorItems, ...formulaItems];
      !!errorItemsWithFormulaProducts.length &&
        this.dispatch(OffersActions.setOutOfStockOrderItems(errorItemsWithFormulaProducts));
      !!formulaSubItems.length &&
        this.dispatch(OffersActions.setOutOfStockOrderItemsFormula(formulaSubItems));
    }
    return null;
  }

  async getQuotationFromOrder({
    idGuest,
    idOffer,
    items,
    formulaItems,
    paymentMethod,
    withdrawRange,
    tableNumber,
  }: UpsertOrderCommand): Promise<any> {
    const { order } = await this.upsertOrder({
      idOffer,
      idGuest,
      items,
      formulaItems,
      withdrawRange,
      tableNumber,
    });

    const {
      //@ts-ignore
      order: orderQuotation,
    } = await this.dispatch(
      OrderThunks.getCartSummaryAdmissionAndPrice({
        idOrder: order.id,
        paymentMethod,
      })
    );

    return {
      order: orderQuotation,
    };
  }

  async getOfferSlots(idOffer: string): Promise<OfferSlot[]> {
    //@ts-ignore
    const { offer } = await this.dispatch(
      OffersThunks.getOfferSlots({
        idOffer,
      })
    );
    return offer?.slots;
  }

  async confirmOrder(payload: ConfirmOrderCommand): Promise<Pick<Order, "id">> {
    //@ts-ignore
    const { order } = await this.dispatch(OrderThunks.confirmOrder(payload));
    return order;
  }

  async getOrderCC(payload: ConfirmOrderCommand): Promise<void> {
    return this.dispatch(OrderThunks.getOrderCC(payload));
  }

  async getBooking(payload: ConfirmOrderCommand): Promise<void> {
    return this.dispatch(OrderThunks.getBooking(payload));
  }

  async cancelOrder(payload: CancelOrderCommand): Promise<Pick<Order, "id">> {
    //@ts-ignore
    const { order } = await this.dispatch(OrderThunks.cancelOrder(payload));
    return order;
  }

  getProductsFormatted(products?: OrderItem[]) {
    return products?.map((product: OrderItem) => ({
      idOfferItem: product?.id || "",
      quantity: product.quantity || 0,
      chosenBaking: product.chosenBaking,
    }));
  }

  divideItemsFromFormulas = (products: [OrderItem , OrderItemFormula]) => {
    return  products.reduce(
      ([offerItem, offerFormulaItem]: any[], product: any) => {
        return product?.offerItem?.inheritedFamily !== ArticleFamily.FORMULA
          ? [
              [
                ...offerItem,
                {
                  idOfferItem: product?.id || "",
                  quantity: product.quantity || 0,
                  chosenBaking: product.chosenBaking,
                },
              ],
              offerFormulaItem,
            ]
          : [
              offerItem,
              [
                ...offerFormulaItem,
                {
                  idOfferItemFormula: product.id,
                  items: product.products.map((subP: OrderItemFormula) => ({
                    idOfferItemFormula: subP.id,
                    quantity: subP.quantity,
                    chosenBaking: subP.chosenBaking,
                  })),
                },
              ],
            ];
      },
      [[], []]
    );
  };

  getDateDescription = (
    withdrawRange: string,
    userLanguage?: string,
    onlyDate: boolean = false,
    endSlotDate: boolean = false
  ): string => {
    const slots = withdrawRange.split("/");

    const initSlot = slots[0];
    const endSlot = slots[1];
    const slot = endSlotDate ? endSlot : initSlot;

    const date = `${_.upperFirst(
      moment(slot)
        .locale(userLanguage || "fr")
        .format("dddd")
    )} ${moment(slot).format("DD/MM/YYYY")}`;

    if (onlyDate) {
      return date;
    }

    const firstHours = moment(initSlot).format("HH:mm");
    const lastHours = moment(endSlot).format("HH:mm");

    return `${date} ${I18n.t("common.at")} ${firstHours} - ${lastHours}`;
  };

  getDate = (
    withdrawRange: string,
    userLanguage?: string,
    onlyDate: boolean = false
  ): string => {
    const slots = withdrawRange.split("/");

    const initSlot = slots[0];
    const endSlot = slots[1];

    const date = `${_.upperFirst(
      moment(initSlot)
        .locale(userLanguage || "fr")
        .format("dddd")
    )} ${moment(initSlot).format("DD/MM/YYYY")}`;

    if (onlyDate) {
      return date;
    }

    const firstHours = moment(initSlot).format("HH:mm");
    const lastHours = moment(endSlot).format("HH:mm");

    return `${date} ${I18n.t("common.at")} ${firstHours} - ${lastHours}`;
  };

  getSmallDateDescription = (cancelableUntil: string): string => {
    const date = moment(cancelableUntil).format("DD/MM/YYYY");
    const hours = moment(cancelableUntil).format("HH:mm");

    return `${date} ${I18n.t("common.at")} ${hours}`;
  };

  async createBookingOrder(
    payload: CreateBookingOrderCommand
  ): Promise<Pick<Order, "id">> {
    //@ts-ignore
    const { order } = await this.dispatch(
      OrderThunks.createBookingOrder(payload)
    );
    return order;
  }

  async updateBookingOrderSlot(
    payload: UpdateBookingOrderSlotCommand
  ): Promise<Pick<Order, "id">> {
    //@ts-ignore
    const { order } = await this.dispatch(
      OrderThunks.updateBookingOrderSlot(payload)
    );
    return order;
  }

  async cancelBookingOrder(
    payload: CancelBookingOrderCommand
  ): Promise<Pick<Order, "id">> {
    //@ts-ignore
    const { order } = await this.dispatch(
      OrderThunks.cancelBookingOrder(payload)
    );
    return order;
  }

  async addOrderRating(
      payload: AddOrderRatingCommand
  ): Promise<AddOrderRatingResponse> {
    return this.dispatch(OrderThunks.addOrderRating(payload)) as unknown as AddOrderRatingResponse;
  }

  async discardOrderRatingNotification(
      payload: DiscardOrderRatingNotificationCommand
  ): Promise<boolean> {
    return this.dispatch(OrderThunks.discardOrderRatingNotification(payload)) as unknown as boolean;
  }

  async recentOrdersToRate(): Promise<RecentOrdersToRateResponse> {
    const { orders } = await this.dispatch(OrderThunks.recentOrdersToRate()) as unknown as RecentOrdersToRateResponse;

    return { orders };
  }

  isTheSameDay = (offerSlot: string, isModifyRange: string) => {
    return (
      moment(offerSlot?.split("/")?.[0]).date() ===
      moment(isModifyRange?.split("/")?.[0]).date()
    );
  };

  getCurrentReservation = (
    bookingOrders: BookingOrder[],
    offerSlot?: string
  ): BookingOrder | undefined => {
    return bookingOrders?.find((b: BookingOrder) => {
      const day = b?.withdrawRange?.split("/")?.[0];
      const offerSlotDay = moment(offerSlot?.split?.("/")?.[0]);
      const dateToUse = offerSlot ? offerSlotDay : moment();
      return dateToUse.isSame(moment(day), "day");
    });
  };

  getIdFromBookingOrder = (bookingOrder: BookingOrder) => {
    const posId = bookingOrder?.bookingOffer?.bookingOfferTemplate?.pos?.id;
    return getDecodedId(posId)?.split?.(":")?.[1];
  };

  getBookingForTheDay = (
    bookingOrders: BookingOrder[],
    day: string,
    idPos?: number
  ) => {
    return bookingOrders?.find((b: BookingOrder) => {
      const id = this.getIdFromBookingOrder(b);
      const bookingDay = moment(b?.withdrawRange?.split?.("/")?.[0])?.format(
        "DD"
      );
      return bookingDay === day && idPos !== id;
    });
  };

  getReservationForSpecificDay = (
    bookingOrders: BookingOrder[],
    day: string
  ) => {
    return bookingOrders?.find((b: BookingOrder) => {
      const bookingDay = moment(b?.withdrawRange?.split?.("/")?.[0])?.format(
        "DD"
      );
      return bookingDay === day;
    });
  };

  getGroupedOrders(ordersList: Order[]) {
    return ordersList.reduce<Record<string, Order[]>>(
      (groupOrders, order) => {
        const orderDate = new Date(order.updated);
        const monthAndYear = `${orderDate.getMonth() + 1}/${orderDate.getFullYear()}`
        const group = groupOrders[monthAndYear] || [];
        group.push(order);
        groupOrders[monthAndYear] = group;
        return groupOrders;
      },
      {}
    );
  }

  getOrderLabelFromState = (state: OrderState) => {
    return {
      ABANDONED: "abandoned",
      ACCEPTED: "accepted",
      CANCELLED: "cancelled",
      CART: "cart",
      COMPLETED: "completed",
      EMPLOYEE_CART: "employeeCart",
      ON_HOLD: "onHold",
      PREPARING: "preparing",
      READY: "ready",
      REFUSED: "refused",
    }[state];
  };

  getOrderLabelColor = (state: OrderState) => {
    const cancelledState =
    state === OrderState.CANCELLED || state === OrderState.REFUSED || state === OrderState.ABANDONED;
    const completedState = state === OrderState.COMPLETED ;

    if (cancelledState) {
      return Colors.redError;
    } else if (completedState) {
      return Colors.darkGrey;
    }
    return Colors.middleGreen;
  };

  getSanitizedQuotationOrder = (
    quotationOrder: any,
    paymentMethod: any,
    withdrawalType: any,
  ): CartSummaryAdmissionAndPrice => {
    return (mapValues(
      quotationOrder,
      (orderInfo: IAmount, key: string): IAmount => {
        if (isObject(orderInfo)) {
          if (key === "totalPrice" && paymentMethod === IPaymentTypes.OnSite && withdrawalType !== OfferTemplateWithdrawalType.TABLE_SERVICE) {
            const totalPrice =
              Number(orderInfo?.amount || 0) +
              Number(quotationOrder?.["fee"]?.amount || 0) +
              Number(quotationOrder?.["admission"]?.amount || 0) +
              Number(quotationOrder?.["subsidies"]?.amount || 0);
            return {
              ...orderInfo,
              amount: `${totalPrice.toFixed(2)}`?.replace(".", ",") || null,
            };
          }
          return {
            ...orderInfo,
            amount: orderInfo?.amount?.replace(".", ",") || null,
          };
        }
        return orderInfo;
      }
    ) as unknown) as CartSummaryAdmissionAndPrice;
  };

  checkIfRefillAllowed(
    quotation: CartSummaryAdmissionAndPrice | undefined,
    priceValue: string,
    balance: string,
    paymentMethod: string | undefined,
    allowedBalance: number
  ): boolean {
    const { managementMode, refillAllowedHolding } =
      useSelector((state: GlobalState) => state?.auth?.userInfo) ?? {};

    const guestBalance = Math.max(Number(balance),allowedBalance)
    const checkBalance =
      !!quotation &&
      Number(priceValue?.replace(",", ".")) > guestBalance &&
      paymentMethod === PaymentMethod.Badge;

      if (!refillAllowedHolding) return false;

      if (managementMode === ManagementMode.PRE) {
        return checkBalance;
      }

      return false;
  }

  isInAdvanceOrder = (withdrawRange: any) =>
    moment(OrderViewModel.TODAY_DATE, "YYYY-MM-DD") <
    moment(withdrawRange?.split("/")?.[1].split("T")?.[0]);

  /*
    Order needs to have been updated in less than 24 hours to be eligible for the user to provide a rating (notation).
   */
  isEligibleForRating = (orderStatus: OrderState, updated: string) => {
    if (orderStatus !== OrderState.COMPLETED) {
      return false;
    }

    const today = moment();
    // We can do order ratings up to 14 days after it is complete
    const pastDay = moment(today).subtract({ days: 14 });

    return moment(updated).isBetween(pastDay, today);
  }
}
